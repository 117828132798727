

.dialogBoxShare {
    height: 120px;
    width: 250px;
    background: #f3dab1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    box-shadow: 0 0 30px rgba(0,0,0,.4);
    border-radius: 10px;
    border-top: solid 3px rgb(171, 0, 0);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .5);
    border-bottom: solid 3px rgb(171, 0, 0);
    overflow: hidden;
}

#clip {
    font-family: "Unna", serif;
    font-weight: bold;
    font-size: larger;
}