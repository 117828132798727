.map-container {
    position: absolute;
    width: 100%;
    height: 100%;      
    background: transparent;
}

.map-outer {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: transparent;
    align-items: center;
}

#map-w {
    width: 100vw;
    height: auto;
    box-shadow: 0px 0px 5px rgb(107, 107, 107);
}

#map-h {
    width: auto;
    height: 100vh;
    box-shadow: 0px 0px 5px rgb(107, 107, 107);
}

#map-hidden {
    opacity: 0;
    box-shadow: none;
}

@keyframes mapIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 100;
    }
}

@supports (-webkit-touch-callout: none) {
    #map-w {
        margin-top: -80px;
    }
}