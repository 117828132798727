
.dialogBoxStats {
    max-height: 75%;
    max-width: 500px;  
    height: auto;
    width: 30%;
    min-width: 300px;
    background: #f3dab1;
    display: flex;
    flex-direction: column;
    text-align: center;
    box-shadow: 0 0 30px rgba(0,0,0,.4);
    border-radius: 10px;
    border-top: solid 3px rgb(171, 0, 0);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .5);
    border-bottom: solid 3px rgb(171, 0, 0);
    overflow-y: scroll;
    overflow-x: hidden;
}

.dialogBoxStats { overflow: -moz-scrollbars-none; }
.dialogBoxStats::-webkit-scrollbar { width: 0 !important }
.dialogBoxStats { -ms-overflow-style: none; }

.statsTitle {
    margin: 0;
    margin-top: 20px;
    font-size: 50px;
    color: rgb(255, 255, 255);
    text-shadow: 1.5px 1.5px 0 rgb(171, 0, 0), 3px 3px 0 black, 0 0 10px rgba(0, 0, 0, 0.3);
}

.statCont {
    display: flex;
    justify-content: center;
}

.stat {
    font-family: "Unna", serif;
    font-size: 20px;
    text-shadow: 0.3px 0.3px 0 rgb(0, 0, 0);
    text-align: left;
    margin-left: 30px;
    margin-right: auto;
}

.statVal {
    font-family: "Unna", serif;
    font-size: 20px;
    font-weight: bold;
    color: rgb(171, 0, 0);
    text-shadow: .7px .7px 0 rgb(0, 0, 0);
    text-align: right;
    margin-right: 30px;
    margin-left: auto;
}