
.dialogCont {
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    animation: winFade 0.5s;
}

#close {
    align-self: center;
    margin-top: 10px;
    margin-bottom: 15px;
    border: none;
    width: 150px;
    height: 50px;
    background-color: rgb(171, 0, 0);
    font-family: "Dancing Script", cursive;
    font-weight: bolder;
    color: rgb(255, 255, 255);
    text-shadow: 1px 1px 0 rgb(0, 88, 143);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    font-size: 30px;
    pointer-events:all;
}

#close:hover {
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.508);
}

#close:active {
    outline: none;
    border: 1px solid grey;
    background-color: rgb(128, 0, 0);
}

@keyframes winFade {
    from {
        background-color: rgba(0, 0, 0, 0);
        backdrop-filter: blur(0px);
    }
    to {
        background-color: rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(2px);
    }
}

@supports (-webkit-touch-callout: none) {
    .dialogCont {
        letter-spacing: 0;
    }
}
