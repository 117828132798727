.App {
    display: flex;
    justify-content: center;
    background: repeating-linear-gradient(45deg, #ffe8d3, #ffe8d3 5px, #f7efe5 5px, #f7efe5 25px);
}

.win-screen {
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(2px);
    animation: winFade 0.5s;
}

.win-screen::after {
    pointer-events: none;
}

.win-cont {
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#win-title {
    font-size: 10vh;
    color: transparent;
    color: rgb(255, 255, 255);
    text-shadow: 2px 2px 0 rgb(171, 0, 0), 4px 4px 0 black, 0 0 10px rgba(0, 0, 0, 0.3);
    font-weight: bold;
    text-align: center;
    margin-bottom: 0;
    animation: winTitleFade 0.5s;
}

.win-info {
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 6vh;
    color: rgb(255, 255, 255);
    text-shadow: 2px 2px 0 rgb(0, 88, 143), 0 0 20px rgba(0, 0, 0, 0.5);
    animation: winInfoFade 1.5s;
}

.sub-info{
    font-size: 4vh;
}

.win-dialog {
    color: #ffe8d3;
}

.win-btns {
    display: flex;
    justify-content: center;
}

.win-btn {
    margin-top: 20px;
    margin-bottom: 0;
    font-size: 4vh;
    color: white;
    text-shadow: 2px 2px 0 rgb(0, 88, 143);
    border: 1px solid #ffffff4d;
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-family: "Dancing Script", cursive;
    font-weight: bold;
    animation: winInfoFade 1.5s;
}

.win-right {
    margin-left: 10px;
}

.win-btn:hover {
    background-color: rgba(255, 255, 255, 0.3);
}
.win-btn:active {
    background-color: rgba(255, 255, 255, 0.1);
}

.vin {
    box-shadow: inset 0 0 250px rgba(0, 0, 0, 0.364);
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

#loader {
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}

#loader-hidden {
    display: none;
    opacity: 0;
}

#loadlet {
    font-size: 20vh;
    font-weight: bold;
    color: transparent;
    text-shadow: 0 0 0px rgba(171, 0, 0, 1), 3px 3px 3px rgba(0, 0, 0, .75);
    font-weight: bold;
    animation: fadeIn .2s;
}

#loadfin {
    font-size: 20vh;
    font-weight: bold;
    text-shadow: 0 0 10px rgba(171, 0, 0, 0), 3px 3px 10px rgba(0, 0, 0, 0);
    color: transparent;
    font-weight: bold;
    animation: loaded 2s;
}

@keyframes winFade {
    from {
        background-color: rgba(0, 0, 0, 0);
        backdrop-filter: blur(0px);
    }
    to {
        background-color: rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(2px);
    }
}

@keyframes winTitleFade {
    from {
        text-shadow: 0px 0px 0 rgba(171, 0, 0, 0), 0px 0px 0 rgba(0, 0, 0, 0), 0 0 10px rgba(0, 0, 0, 0);
    }
    to {
        text-shadow: 2px 2px 0 rgb(171, 0, 0), 4px 4px 0 black, 0 0 10px rgba(0, 0, 0, 0.3);
    }
}

@keyframes winInfoFade {
    from {
        color: rgba(255, 255, 255, 0);
        text-shadow: 2px 2px 0 rgba(0, 88, 143, 0), 0 0 20px rgba(0, 0, 0, 0);
    }
    to {
        color: rgb(255, 255, 255);
        text-shadow: 2px 2px 0 rgb(0, 88, 143), 0 0 20px rgba(0, 0, 0, 0.5);
    }
}

@keyframes fadeIn {
    from {
        text-shadow: 0 0 0px rgba(171, 0, 0, 0), 3px 3px 0px rgba(0, 0, 0, 0);
    }
    to {
        text-shadow: 0 0 0px rgba(171, 0, 0, 1), 3px 3px 3px rgba(0, 0, 0, .75);
    }
}

@keyframes loaded {
    0% {
        text-shadow: 0 0 0px rgba(171, 0, 0, 1), 3px 3px 0 rgba(0, 0, 0, 0.504);
    }
    100% {
        text-shadow: 0 0 2px rgba(171, 0, 0, 0), 3px 3px 2px rgba(0, 0, 0, 0);
    }
}
