.dialogBoxHelp {
    max-height: 75%;
    max-width: 500px;
    height: auto;
    width: 35%;
    min-width: 300px;
    background: #f3dab1;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    overflow-x: hidden;
    text-align: center;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.4);
    border-top: solid 3px rgb(171, 0, 0);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5);
    border-bottom: solid 3px rgb(171, 0, 0);
    border-radius: 10px;
}

.dialogBoxHelp { overflow: -moz-scrollbars-none; }
.dialogBoxHelp::-webkit-scrollbar { width: 0 !important }
.dialogBoxHelp { -ms-overflow-style: none; }

.helpIntro {
    margin: 0;
    margin-top: auto;
    font-size: 40px;
    color: rgb(171, 0, 0);
    text-shadow: 1px 1px 0 rgb(0, 0, 0);
}

.helpTitle {
    margin: 0;
    margin-bottom: 15px;
    font-size: 70px;
    color: rgb(255, 255, 255);
    text-shadow: 2px 2px 0 rgb(171, 0, 0), 4px 4px 0 black, 0 0 10px rgba(0, 0, 0, 0.3);
    font-weight: bold;
}

.desc {
    margin: 0;
    margin-bottom: auto;
    margin-top: 10px;
    font-size: 20px;
    text-shadow: 0.3px 0.3px 0 rgb(0, 0, 0);
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    font-family: "Unna", serif;
}

.hintCont {
    margin-bottom: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 3px solid rgb(0, 88, 143);
    border-top: 3px solid rgb(0, 88, 143);
    background-color: rgba(255, 255, 255, 0.1);
}

.hint {
    margin-top: 0;
    text-align: left;
    padding-left: 30px;
}

.endCont {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(171, 0, 0);
    text-shadow: 0.3px 0.3px 0 rgb(0, 0, 0);
    font-size: 20px;
    border-top: 3px solid rgb(171, 0, 0);
    border-bottom: 3px solid rgb(171, 0, 0);
    margin-bottom: 5px;
    background-color: rgba(255, 255, 255, 0.1);
    font-family: "Unna", serif;
}

.gita {
    height: 75%;
    margin-right: 10px;
}

.gitb {
    height: 100%;
    width: 100%;
    font-family: "Dancing Script", cursive;
    font-weight: bolder;
    color: rgb(0, 88, 143);
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.494);
    font-size: 20px;
}

.gitb,
.gitb:focus {
    outline: none;
    background: transparent;
    border: 1px solid transparent;
}

.gitb:hover {
    background-color: rgba(0, 0, 0, 0.057);
    border-radius: 10px;
}

.gitb:active {
    background-color: rgba(0, 0, 0, 0.105);
    border-radius: 10px;
}

.desc-end {
    margin-right: auto;
    margin-left: 20px;
}

.desc-end-link {
    margin-right: 20px;
}

.tabbr {
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    line-height: 10px;
}

@media (max-width: 350px) {
    .endCont {
        flex-direction: column;
    }
    .desc-end {
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 0;
        margin-top: 10px;
    }
    .desc-end-link {
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 10px;
        margin-top: 10px;
    }
}

@media (min-width: 1055px) {
    .helpbr {
        display: none !important;
    }
    .tabbr {
        display: none !important;
    }
}
