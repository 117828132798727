
.hide {
    display: none;
}

.show {
    animation: winFade 0.7s;
}

.dialogBoxInfo {
    max-height: 560px;
    max-width: 400px;  
    height: auto;
    min-width: 300px;
    background: #f3dab1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    box-shadow: 0 0 30px rgba(0,0,0,.4);
    border-radius: 10px;
    border-top: solid 3px rgb(171, 0, 0);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .5);
    border-bottom: solid 3px rgb(171, 0, 0);
    overflow-x: hidden;
    overflow-y: scroll;
}

.dialogBoxInfo { overflow: -moz-scrollbars-none; }
.dialogBoxInfo::-webkit-scrollbar { width: 0 !important }
.dialogBoxInfo { -ms-overflow-style: none; }

.infoTitle {
    margin: 0;
    margin-top: 10px;
    font-size: 50px;
    color: rgb(255, 255, 255);
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-shadow: 1.5px 1.5px 0 rgb(171, 0, 0), 3px 3px 0 black, 0 0 10px rgba(0, 0, 0, 0.3);
}

#thumbnail {
    margin-top: 10px;
    align-self: center;
    width: 50%;
    box-shadow: 0 0 5px rgb(130, 130, 130);
}

.attribution {
    font-family: "Unna", serif;
    font-size: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

@keyframes winFade {
    from {
        background-color: rgba(0, 0, 0, 0);
        backdrop-filter: blur(0px);
    }
    to {
        background-color: rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(2px);
    }
}